<template>
  <div class="container">
    <router-view/>
  </div>
  <layout-footer></layout-footer>
</template>

<style>
body{
  background-color: #17212f;
}

.container{
  margin: 0px auto;
  max-width: 1400px;
}

h1{
  margin-top: 0px;
}

a{
  color: var(--primary-color);
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background-color: #17212f;
  padding: 40px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* Question layout */
.question_container{
  display: flex;
}

.question_container .question_answers{
  flex: 0 0 75%;
  background-color: #1f2d40;
  padding: 20px;
}

.question_container .question_info{
  flex: 0 0 25%;
  padding-left: 20px
}

/* Input */
input{
  width: 100%;
}

.p-float-label{
  margin-top: 26px;
}

/* Buttons */
.p-selectbutton .p-button label{
  margin-left: 5px;
}

.communication_list.persona_overview .p-selectbutton.p-buttonset .p-button,
.social_network_list.persona_overview .p-selectbutton.p-buttonset .p-button{
  width: 50%;
  border-right: 1px solid #304562;
  border-radius: 3px;
}

/* Radio buttons */
.rb_choices{
  display: flex;
}

.p-field-radiobutton{
  padding: 5px 10px;

}

.p-field-radiobutton label{
  margin-left: 10px;
}

/* Checkbox list */
.cb_list{
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
}

.p-field-checkbox{
  flex: 0 0 50%;
  padding: 0px 20px 0px 0px;
  margin-bottom: 10px;
}

.p-field-checkbox label{
  margin-left: 20px;
}

/* Form inputs */
.p-inputtextarea{
  width: 100%;
}
</style>
<script>
import LayoutFooter from "@/components/layout/layout-footer";
export default {
  components: {LayoutFooter}
}
</script>