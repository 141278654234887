const mutations = {
    updateSteps: (state, payload) => {
        state.hasSteps.push(payload.stepPath);
    },
    setPersonaName: (state, payload) => {
        state.personaName = payload.name;
    },
    setAvatar: (state, payload) => {
        state.avatar = payload.avatar;
    },
    setPersonaAge: (state, payload) => {
        state.personaAge = payload.age;
    },
    setLevelOfSchool: (state, payload) => {
        state.levelOfSchool = payload.level;
    },
    setIndustry: (state, payload) => {
        state.industry = payload.industry;
    },
    setSizeOfOrganization: (state, payload) => {
        state.sizeOfOrganization = payload.size;
    },
    setJobTitle: (state, payload) => {
        state.jobTitle = payload.title;
    },
    setJobMeasured: (state, payload) => {
        state.jobMeasured = payload.measured;
    },
    setWhoReportTo: (state, payload) => {
        state.whoReportTo = payload.title;
    },
    setGoalsOrObjectives: (state, payload) => {
        state.goalsOrObjectives = payload.goalsOrObjects;
    },
    updateListBiggestChallenges: (state, payload) => {
        state.listBiggestChallenges.push(payload.additionalChallenge);
    },
    setPersonaBiggestChallenges: (state, payload) => {
        state.personaBiggestChallenges = payload.biggestChallenges;
    },
    updatePersonaBiggestChallenges: (state, payload) => {
        state.personaBiggestChallenges.push(payload.challenge);
    },
    setJobResponsibilities: (state, payload) => {
        state.jobResponsibilities = payload.responsibilities;
    },
    updateListTools: (state, payload) => {
        state.listTools.push(payload.tool);
    },
    setPersonaTools: (state, payload) => {
        state.personaTools = payload.tools;
    },
    updatePersonaTools: (state, payload) => {
        state.personaTools.push(payload.tool);
    },
    setPersonaCommunicate: (state, payload) => {
        state.personaCommunicate = payload.communicate;
    },
    setHowGainInformationForJob: (state, payload) => {
        state.howGainInformationForJob = payload.description;
    },
    setPersonaSocialNetwork: (state, payload) => {
        state.personaSocialNetwork = payload.social;
    },
    setDisplayHubspotForm: (state, payload) => {
        state.displayHubspotForm = payload.displayModal;
    },
}

export default mutations;
