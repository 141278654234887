<template>
  <footer>
    <div class="container">
      <div class="footer_cols">
        <div class="footer_logo">
          <img src="/gfx/subscription_circle_logo_stacked_web.png">
        </div>
        <div class="footer_contact">
          <h4>Kontakt</h4>
          <p>
            Subscription Circle<br>
            +45 61 72 88 48<br>
            hello@subscriptioncircle.com
          </p>
          <p>
            Vores <a target="_blank" href="https://subscriptioncircle.com/privatlivspolitik">privatlivspolitik</a>
          </p>
        </div>
      </div>
      <div class="footer_copy">

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "layout-footer",
}
</script>

<style scoped>
footer{
  padding: 54px 0px;
}

.footer_cols{
  display: flex;
}

.footer_logo{
  flex: 0 0 75%;
  max-width: 75%;
  text-align: center;
}

.footer_contact{
  flex: 0 0 25%;
  max-width: 25%;
}

.footer_contact p{
  color: #666666;
  line-height: 1.5;
}

.footer_copy{
  text-align: center;
}

</style>