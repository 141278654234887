const actions = {
    updateSteps: ({commit}, payload) => {
        commit('updateSteps', payload)
    },
    setPersonaName: ({commit}, payload) => {
        commit('setPersonaName', payload)
    },
    setAvatar: ({commit}, payload) => {
        commit('setAvatar', payload)
    },
    setPersonaAge: ({commit}, payload) => {
        commit('setPersonaAge', payload)
    },
    setLevelOfSchool: ({commit}, payload) => {
        commit('setLevelOfSchool', payload)
    },
    setIndustry: ({commit}, payload) => {
        commit('setIndustry', payload)
    },
    setSizeOfOrganization: ({commit}, payload) => {
        commit('setSizeOfOrganization', payload)
    },
    setJobTitle: ({commit}, payload) => {
        commit('setJobTitle', payload)
    },
    setJobMeasured: ({commit}, payload) => {
        commit('setJobMeasured', payload)
    },
    setWhoReportTo: ({commit}, payload) => {
        commit('setWhoReportTo', payload)
    },
    setGoalsOrObjectives: ({commit}, payload) => {
        commit('setGoalsOrObjectives', payload)
    },
    updateListBiggestChallenges: ({commit}, payload) => {
        commit('updateListBiggestChallenges', payload)
    },
    setPersonaBiggestChallenges: ({commit}, payload) => {
        commit('setPersonaBiggestChallenges', payload)
    },
    updatePersonaBiggestChallenges: ({commit}, payload) => {
        commit('updatePersonaBiggestChallenges', payload)
    },
    setJobResponsibilities: ({commit}, payload) => {
        commit('setJobResponsibilities', payload)
    },
    updateListTools: ({commit}, payload) => {
        commit('updateListTools', payload)
    },
    setPersonaTools: ({commit}, payload) => {
        commit('setPersonaTools', payload)
    },
    updatePersonaTools: ({commit}, payload) => {
        commit('updatePersonaTools', payload)
    },
    setPersonaCommunicate: ({commit}, payload) => {
        commit('setPersonaCommunicate', payload)
    },
    setHowGainInformationForJob: ({commit}, payload) => {
        commit('setHowGainInformationForJob', payload)
    },
    setPersonaSocialNetwork: ({commit}, payload) => {
        commit('setPersonaSocialNetwork', payload)
    },
    setDisplayHubspotForm: ({commit}, payload) => {
        commit('setDisplayHubspotForm', payload)
    },

}

export default actions