<template>
  <div class="home">
    <img src="/gfx/personas.png" alt="Personas" />
    <h1>Buyer Persona generator</h1>
    <p>Opret en Buyer Persona, som hele din virksomhed kan bruge til at markedsføre, sælge og servicere bedre.</p>
    <Button @click="gotToFirstStep">Byg min Persona</Button>
  </div>
</template>

<script>

import {useRouter} from "vue-router";

export default {
  name: 'Home',
  setup(){

    const router = useRouter();

    function gotToFirstStep(){
      console.log("Go to first step")
      router.push('/create-avatar')
    }

    return{
      gotToFirstStep
    }
  }
}
</script>
<style scoped>
  .home{
    text-align: center;
  }
</style>
