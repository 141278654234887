const state = {
    hasSteps: [],
    personaName: "",
    avatar: "avatar_blond_guy",
    personaAge: "",
    levelOfSchool: "",
    industry: null,
    sizeOfOrganization: "",
    jobTitle: "",
    jobMeasured: "",
    whoReportTo: "",
    goalsOrObjectives: "",
    listBiggestChallenges: [
        {
            id: 'navigatingClientRelationshipsCommunications',
            val: 'Navigering af kundeforhold og kommunikation'
        },
        {
            id: 'employeeMorale',
            val: 'Medarbejder moral'
        },
        {
            id: 'communication',
            val: 'Kommunikation'
        },
        {
            id: 'projectManagementDisorganization',
            val: 'Projektledelse og disorganisering'
        },
        {
            id: 'problemSolvingDecisionMaking',
            val: 'Problemløsning og beslutningstagning'
        },
        {
            id: 'resources',
            val: 'Ressourcer'
        },
        {
            id: 'changeManagement',
            val: 'Forandringsledelse'
        },
        {
            id: 'collaborationCreativity',
            val: 'Samarbejde og kreativitet'
        },
        {
            id: 'professionalDevelopment',
            val: 'Professionel udvikling'
        },
    ],
    personaBiggestChallenges: [],
    jobResponsibilities: "",
    listTools: [
        {
            id: 'AccountingBookkeepingSystems',
            val: 'Regnskab og bogholderi'
        },
        {
            id: 'ContentManagementSystems',
            val: 'CMS'
        },
        {
            id: 'Cloud-BasedStorageFileSharing',
            val: 'Cloud-baserede applikationer til lagring og fildeling'
        },
        {
            id: 'CRM',
            val: 'CRM'
        },
        {
            id: 'Projektstyringssystemer',
            val: 'Projektstyringssystemer'
        },
        {
            id: 'E-mailProgrammer',
            val: 'E-mail programmer'
        },
        {
            id: 'OpgaveStyringsSoftware',
            val: 'Opgave styrings software'
        },
        {
            id: 'Tekstbehandling',
            val: 'Tekstbehandling'
        },
    ],
    personaTools: [],
    listCommunicate: [
        {name: 'Telefon', icon: 'pi pi-phone', value: 'Telefon'},
        {name: 'E-mail', icon: 'pi pi-envelope', value: 'E-mail'},
        {name: 'SMS', icon: 'pi pi-comments', value: 'SMS'},
        {name: 'Sociale Medier', icon: 'pi pi-facebook', value: 'Sociale Medier'},
        {name: 'Ansigt til ansigt', icon: 'pi pi-users', value: 'Ansigt til ansigt'}
    ],
    personaCommunicate: null,
    howGainInformationForJob: "",
    socialNetworkOptions: [
        {name: 'Facebook', icon: 'pi pi-facebook', value: 'Facebook'},
        {name: 'Instagram', icon: 'icon-instagram', value: 'Instagram'},
        {name: 'Twitter', icon: 'pi pi-twitter', value: 'Twitter'},
        {name: 'LinkedIn', icon: 'icon-linkedin', value: 'LinkedIn'},
        {name: 'Pinterest', icon: 'icon-pinterest', value: 'Pinterest'},
    ],
    personaSocialNetwork: null,
    displayHubspotForm: false
}

export default state